import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../container/main'
import SEO from '../components/seo'

import Container from '../components/container'
import ContentHeroNew from '../components/content-hero-new'
import ContentInlineNav from '../components/content-inline-nav'
import ContentBlockLarge from '../components/content-block-large'
import ContentBlockSmall from '../components/content-block-small'
import ContentBannerWithCTA from '../components/content-banner-with-cta'

// import IconPasswordManager from '../../assets/feature-icons/password-manager.png'
// import IconOrganizeYourDailyAgenda from '../../assets/feature-icons/organize-your-daily-agenda.png'
// import IconFindEverythingCrossApp from '../../assets/feature-icons/find-every-thing-cross-app.png'
// import IconWorkWithDataCrossApp from '../../assets/feature-icons/work-with-data-cross-app.png'
// import IconNotificationsAtAGlance from '../../assets/feature-icons/notifications-at-a-glance.png'

// import IconAllAppsOneSolution from '../../assets/feature-icons/all-apps-one-solution.png'
// import IconMultiDevice from '../../assets/feature-icons/multi-device.png'
// import IconManageSaasUserInOnePlace from '../../assets/feature-icons/manage-saas-users-in-one-place.png'
// import IconShareAnyAppWithoutEffort from '../../assets/feature-icons/share-any-app-without-effort.png'
// import IconAnalyzeOptimizeSaasUsage from '../../assets/feature-icons/analyze-optimize-saas-usage.png'

const IndexPage = ({ data }) => (
  <MainContainer>
    <SEO title="Basaas Workplace" />
    <Container>
      <ContentHeroNew
        // kicker="Optimize your app management"
        headline="Security and data privacy at Basaas"
        description={
          <React.Fragment>
            <p>
              At Basaas, we take our customers’ security very seriously and have extensive experience serving enterprise clients with complex security requirements. We also take the security of our
              infrastructure very seriously and are committed to providing an environment that is safe, secure, and stable.{' '}
            </p>
            <p> We are aware of our responsibility of sensitive company data. The Basaas security concept is based on international best in class standards and EU requirements. </p>
          </React.Fragment>
        }
        // actionLinkText="Get started – It's free!"
        // actionLinkIsExternal
        // actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup&utm_content=home"
        media={<StaticImage src="../assets/images/security-hero.jpg" alt="Optimize your app management" placeholder="blurred" />}
      />
    </Container>

    <Container withBackground style={{ padding: '80px 0' }} id="workplace-for-teams">
      <ContentBlockLarge
        // kicker="Your tailor-made best of breed solution"
        headline="Fully comply with European data security regulations including GDPR"
        description="Basaas is committed to ensuring compliance with GDPR requirements and accountability at all levels. For European companies, we guarantee that data storage takes place exclusively in the EU. Data protection goes beyond the work of our security and privacy teams; every Basaas employee is trained to do their part to keep your data safe. Regarding the principles of GDPR we rely on data minimization. If your apps are integrated in Basaas, we only fetch and show data from your apps to you, but we do not store any data from your apps."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/security-gdpr.png" alt="Optimize your app management" placeholder="blurred" />}
        // reverse
      />

      <ContentBlockLarge
        // kicker="Your tailor-made best of breed solution"
        headline="Certified data centers with the highest level of security"
        description="Basaas is hosted in data centers of a worldwide leading infrastructure provider with the highest level of security and fully compliant with industry standards. The data centers undergo regularly independent verification of security, privacy, and compliance controls, achieving certifications and global standard in order to earn your trust. We also monitor our infrastructure 24/7/365 to detect any anomaly or outage."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/security-data-centers.png" alt="Optimize your app management" placeholder="blurred" />}
      />

      <ContentBlockLarge
        // kicker="Your tailor-made best of breed solution"
        headline="World-leading security solutions to secure your company data"
        description="Similar to our infrastructure, we rely on fully managed and worldwide leading database providers, which undergo independent audits to make sure they meet highest compliance standards for data security. We create multiple daily backups of your data which are stored securely in the same geographic region. All databases have high availability (replication), high levels of security, reliability and state-of-the-art backup/recovery mechanisms. Our databases are fully encrypted and allow only secure and trusted connections (end-to-end encryption)."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/security-security-solutions.png" alt="Optimize your app management" placeholder="blurred" />}
        // reverse
      />

      <ContentBlockLarge
        // kicker="Latest security standards for data transmission"
        headline="Latest security standards for data transmission"
        description="Basaas is compliant with the latest security standards for data transmission. Any communication between the end-user (client) and Basaas infrastructure is secured using the industry standard TLS 1.3 (min. TLS 1.2) encryption. All communication between Basaas infrastructure and databases is secured additionally via SSL, encryption at rest and IP Whitelisting. Furthermore, all passwords and credentials are hashed and salted using modern encryption methods. When using encryption, we use AES-256 or better."
        // actionLinkText="Learn more"
        // actionLink="/workplace"
        media={<StaticImage src="../assets/images/security-latest-standards.png" alt="Optimize your app management" placeholder="blurred" />}
      />
    </Container>

    <Container style={{ padding: '80px 0' }}>
      <ContentBannerWithCTA />
    </Container>
  </MainContainer>
)

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `
