import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { contentInlineNavContainer } from './index.module.scss'

const ContentInlineNav = props => (
  <div className={`${contentInlineNavContainer} ${props.media ? '' : 'full-width'}`}>
    <div className="item primary">
      {props.media && <div className="media-container">{props.media}</div>}
      <div className="content">
        <span className="kicker">{props.kicker}</span>
        <span className="headline">{props.headline}</span>
      </div>
    </div>
    <div className="item secondary">{props.secondary}</div>
  </div>
)

// ContentBlockAlternating.propTypes = {
//   mediaUrl: PropTypes.string,
//   mediaAlt: PropTypes.string,
//   kicker: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string,
//   isExternalLink: PropTypes.bool,
//   linkTo: PropTypes.string,
//   linkText: PropTypes.string,
// };

// ContentBlockAlternating.defaultProps = {
//   isExternalLink: false,
// };

export default ContentInlineNav
